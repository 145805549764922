<ng-container *ngIf="data?.type?.includes(SectionComponentsEnum.SERVICESECTIONIMAGE1)">
  <remax-service-section-image [tile]="data"></remax-service-section-image>
</ng-container>
<ng-container *ngIf="data?.type?.includes(SectionComponentsEnum.SERVICESECTIONIMAGE2)">
  <remax-service-section-image-2 [tile]="data"></remax-service-section-image-2>
</ng-container>
<ng-container *ngIf="data?.type?.includes(SectionComponentsEnum.SERVICESECTIONIMAGE3)">
  <remax-service-section-image-3 [tile]="data"></remax-service-section-image-3>
</ng-container>
<ng-container *ngIf="data?.type?.includes(SectionComponentsEnum.SERVICESECTIONIMAGE4)">
  <remax-service-section-image-4 [tile]="data"></remax-service-section-image-4>
</ng-container>
