import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { ServiceSectionImageComponent } from '../service-section-image/service-section-image.component';
import { ServiceSectionImage2Component } from '../service-section-image-2/service-section-image-2.component';
import { ServiceSectionImage3Component } from '../service-section-image-3/service-section-image-3.component';
import { ServiceSectionImage4Component } from '../service-section-image-4/service-section-image-4.component';
import { SectionComponentsEnum } from '../section/model/section-components.enum';
import { ServiceSectionImage } from '../service-section-image/models/service-section-image.model';

@Component({
  imports: [
    CommonModule,
    FontAwesomeModule,
    ServiceSectionImageComponent,
    ServiceSectionImage2Component,
    ServiceSectionImage3Component,
    ServiceSectionImage4Component
  ],
  selector: 'remax-service-section-image-select',
  templateUrl: './service-section-image-select.component.html',
  styleUrls: ['./service-section-image.component.scss']
})
export class ServiceSectionImageSelectComponent {
  @Input() data: ServiceSectionImage;
  documentToHtmlString = documentToHtmlString;
  SectionComponentsEnum = SectionComponentsEnum;
  getIcon(icon: IconName, iconClass: string): string[] {
    return [iconClass, icon];
  }
}
